import { csrfTokenService } from '~/apps/csrf/CsrfTokenService'
import { getDomainName, langFallback$Fetch } from '~/lang/utils/langfetch'
import { getGeoPointFromWKTPoint } from '~~/utility/geo/GeoLocation'
import { getTransformObject } from '~~/shared/photos/transforms'
import {
  FavouriteCampsiteDetails,
  FavouriteCampsitesRequestData,
  FavouritesBasicWithWishlistsAndPrimaryPhoto,
  Wishlist,
} from '../types'

type PrivacyType = 'private' | 'public'
type CampsitesRequestData = {
  campsite_id: number
  notes?: string
}

interface WishlistWithItemsFetch
  extends Omit<Wishlist, 'isOwner' | 'itemCount' | 'primaryPhoto'> {
  is_owner: boolean
  item_count: number
  primary_photo: { title: string; url: string } | null
  items: Record<
  number,
  {
    campsite_id: string
    created: string
    notes: string
  }
  >
}
type WishlistWithItems = Wishlist & {
  items: Record<
  number,
  {
    created: string
    notes: string
  }
  >
}

interface PhotoResponse {
  caption?: string
  url: {
    master_image: string
  }
}
type EldarionFetchResult = {
  alfred_with_lists?: {
    favourite_dated_with_lists?: string
  }
  favourites_primary_photo: string
}

export class FavouritesRepository {
  langCode = 'en-gb'

  constructor(langCode: string) {
    this.langCode = langCode
  }

  async add(campsiteId: string) {
    const url = `/_/togglefavourite/${campsiteId}/?domain=${getDomainName()}`
    const response = await this.doEldarionFetch<{ primary_photo: string }>(
      url,
      'POST',
    )
    return JSON.parse(response.primary_photo)
  }

  async addNewList(
    name: string,
    campsites?: CampsitesRequestData[],
    privacy: PrivacyType = 'private',
  ) {
    const body = {
      name: name,
      status: privacy,
      campsites,
    }
    try {
      const response: { list_id: string } = await this.doFetch(
        '/_/favourite-list/',
        'POST',
        { body },
      )
      return { listId: response.list_id }
    } catch (error) {
      const { data } = error as { data: { errors: Record<string, string[]> } }
      throw data.errors
    }
  }

  async changeListPrivacy(listId: string, privacy: PrivacyType) {
    const body = { status: privacy }
    const response = await this.doFetch(
      `/_/favourite-list/${listId}/`,
      'PATCH',
      { body },
    )
    return response
  }

  async addFavouriteToWishlist(
    listId: string,
    campsiteId: string,
    note: string,
  ) {
    const body = { campsite: campsiteId, notes: note }
    const { list_id, item_count, primary_photo, ...rest } = await this.doFetch<{
      list_id: string
      item_count: number
      primary_photo: { title: string; url: string } | null
    }>(`/_/favourite-list/${listId}/add-campsite/`, 'POST', { body })
    return {
      id: list_id,
      itemCount: item_count,
      primaryPhoto: primary_photo,
      ...rest,
    }
  }

  async remove(campsiteId: string) {
    const url = `/_/togglefavourite/${campsiteId}/?domain=${getDomainName()}`
    const response = await this.doEldarionFetch<{ primary_photo: string }>(
      url,
      'POST',
    )
    return JSON.parse(response.primary_photo)
  }

  async removeFromWishlist(listId: string, campsiteId: string) {
    await this.doFetch(`/_/favourite-list/${listId}/${campsiteId}/`, 'DELETE')
  }

  async getAllWishlists(): Promise<Record<string, Wishlist>> {
    try {
      const wishlists = await this.doFetch<
      Record<string, WishlistWithItemsFetch>
      >('/_/favourite-list/', 'GET')
      const transformedWishlists: Record<string, Wishlist> = {}
      for (const [
        key,
        { is_owner, item_count, primary_photo, ...rest },
      ] of Object.entries(wishlists)) {
        transformedWishlists[key] = {
          ...rest,
          isOwner: is_owner,
          itemCount: item_count,
          primaryPhoto: primary_photo,
        }
      }
      return transformedWishlists
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Ignore the 401 Unauthorized error
        return {}
      } else {
        throw error
      }
    }
  }

  async removeWishlist(wishlistId: string) {
    // TODO: add status check
    return await this.doFetch(`/_/favourite-list/${wishlistId}/`, 'DELETE')
  }

  async getWishlistDetails(wishlistId: string): Promise<WishlistWithItems> {
    const { is_owner, item_count, primary_photo, ...rest } =
      await this.doFetch<WishlistWithItemsFetch>(
        `/_/favourite-list/${wishlistId}/`,
        'GET',
      )
    return {
      ...rest,
      isOwner: is_owner,
      itemCount: item_count,
      primaryPhoto: primary_photo,
    }
  }

  async getDetailedCampsitesNoPagination(
    wishlistId: string,
  ): Promise<FavouriteCampsiteDetails[]> {
    const response = await this.doFetch<any>(
      `/_/favourite-campsite-details/no-pagination/${wishlistId}/`,
    )
    return this.mapResults(response.results)
  }

  async updateFavouriteInWishlist(
    listId: string,
    campsiteId: string,
    note: string,
  ) {
    try {
      const body = { list_id: listId, campsite_id: campsiteId, notes: note }
      const response: { campsite_id: string; list_id: string; notes: string } =
        await this.doFetch('/_/favourite-list/notes/', 'PUT', { body })
      return {
        data: {
          campsiteId: response.campsite_id,
          listId: response.list_id,
          notes: response.notes,
        },
      }
    } catch (error) {
      return {
        data: error,
      }
    }
  }

  async getAll(): Promise<FavouritesBasicWithWishlistsAndPrimaryPhoto> {
    const results: EldarionFetchResult = await this.doEldarionFetch(
      '/_/get_all_favourites/',
    )
    const favouriteDatedWithLists = JSON.parse(
      results.alfred_with_lists?.favourite_dated_with_lists || '{}',
    )
    const favouritesPrimaryPhoto = JSON.parse(results.favourites_primary_photo)
    return { favouriteDatedWithLists, favouritesPrimaryPhoto }
  }

  async getDetailedCampsites(
    page?: number,
    listId?: string,
  ): Promise<FavouriteCampsitesRequestData> {
    const query: string[] = []
    if (page) query.push(`page=${page}`)
    if (listId) query.push(`list_id=${listId}`)
    const queryPart = query.length ? `?${query.join('&')}` : ''
    const response: any = await this.doFetch(
      `/_/favourite-campsite-details/${queryPart}`,
    )
    return {
      ...response,
      results: this.mapResults(response.results),
    }
  }

  mapResults(results): FavouriteCampsiteDetails[] {
    return results.map((campsite: any) => {
      return {
        ...campsite,
        id: campsite.id.toString(),
        status: campsite.bookable ? 'bookable' : 'free',
        nutshells: campsite.nutshells || [],
        point: campsite.point
          ? getGeoPointFromWKTPoint(campsite.point)
          : undefined,
        primaryPhoto: campsite.primaryPhoto
          ? this.mapPhoto(campsite.primaryPhoto)
          : undefined,
        leadPriceAdults:
          campsite.leadPricePersonsIncluded ||
          Number(process.env.defaultAdultsCount),
      }
    })
  }

  private mapPhoto(photo: PhotoResponse) {
    return {
      caption: photo.caption || '',
      url: getTransformObject('favourite', photo.url.master_image),
    }
  }

  private async doFetch<T>(
    url: string,
    method: string = 'GET',
    content: object = {},
  ) {
    const { csrfToken } = await csrfTokenService()
    return await langFallback$Fetch<T>(this.langCode, url, {
      method: method,
      headers: {
        'X-CSRFToken': csrfToken,
      },
      ...content,
    })
  }
  private async doEldarionFetch<T>(url: string, method: string = 'GET') {
    const { csrfToken } = await csrfTokenService()
    return await langFallback$Fetch<T>(this.langCode, url, {
      method: method,
      headers: {
        'X-ELDARION-AJAX': 'true',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': csrfToken,
      },
    })
  }

  async updateWishlist(
    listId: string,
    updates: { name: string; status?: PrivacyType },
  ) {
    try {
      const response = await this.doFetch(
        `/_/favourite-list/${listId}/`,
        'PATCH',
        { body: updates },
      )
      return response
    } catch (error) {
      const { data } = error as { data: { errors: Record<string, string[]> } }
      throw data.errors
    }
  }
}
